import React from 'react';
import { MenuItemProps, Tooltip } from 'antd';

import { StyledMenuItem } from './styles';

interface IProps extends MenuItemProps {
    isCollapsed: boolean;
}

const SoloMenuItem: React.FC<IProps> = ({
    title,
    icon,
    isCollapsed,
    ...rest
}) => {
    return (
        <Tooltip placement="left" title={isCollapsed ? title : null}>
            <StyledMenuItem {...rest}>
                {isCollapsed ? (
                    <>{icon}</>
                ) : (
                    <>
                        {icon}
                        <span>{title}</span>
                    </>
                )}
            </StyledMenuItem>
        </Tooltip>
    );
};

export default SoloMenuItem;
