import { useMutation } from '@tanstack/react-query';
import api from '../../../services/api';

type React = 'LIKE' | 'DISLIKE' | null;

export interface RequestReaction {
    internal_notification_id: string;
    reaction: React;
}

interface ResponseReaction {
    id: number;
    internal_notification_id: number;
    user_id: number;
    read_at: string;
    created_at: string;
    updated_at: string;
    group: string;
    reaction: React;
}

const createReactAnnouncementFetch = async (
    reqBody: RequestReaction,
): Promise<ResponseReaction> => {
    const { data }: { data: ResponseReaction } = await api.post(
        `/shopmanager/internal-notification/react`,
        {
            ...reqBody,
        },
    );

    return data;
};

export const useCreateReactAnnouncementMutation = () => {
    return useMutation({
        mutationFn: (reqBody: RequestReaction) =>
            createReactAnnouncementFetch(reqBody),
    });
};
