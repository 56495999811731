import React from 'react';
import { Dropdown } from 'antd';
import { withTheme, ThemeProps, DefaultTheme } from 'styled-components';
import { useHistory } from 'react-router-dom';
import AvatarDropDownMenu from './Menu';

import {
    Container,
    StyledAvatar,
    Notification,
    NotificationIndicator,
    Icon,
} from './styles';

import avatar from '../../../assets/images/avatar_women.svg';
import bill_outline from '../../../assets/images/bell-outline.svg';
import bill_filled from '../../../assets/images/bell-filled.svg';

import { useAuth } from '../../../contexts/Auth';
import { useGetAnnouncementsShopmanager } from '../../../store/Announcement';

const AvatarMenu: React.FC<ThemeProps<DefaultTheme>> = () => {
    const [visible, setVisible] = React.useState(false);
    const { isAdmin } = useAuth();
    const navigation = useHistory();

    const { data } = useGetAnnouncementsShopmanager({
        'filters[isRead]': false,
    });

    return (
        <Container>
            {!isAdmin && data && data.data.length > 0 && (
                <Notification
                    onClick={() => navigation.push('/announcements/view')}
                >
                    <Icon src={bill_filled} />
                    <NotificationIndicator />
                </Notification>
            )}

            {!isAdmin && data && data.data.length <= 0 && (
                <Notification
                    onClick={() => navigation.push('/announcements/view')}
                >
                    <Icon src={bill_outline} />
                </Notification>
            )}

            <Dropdown
                onVisibleChange={() => setVisible((prev) => !prev)}
                visible={visible}
                overlay={<AvatarDropDownMenu setVisible={setVisible} />}
                trigger={['click']}
                placement="bottomLeft"
                arrow
            >
                <StyledAvatar size="default" src={avatar} />
            </Dropdown>
        </Container>
    );
};
export default withTheme(AvatarMenu);
