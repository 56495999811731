import React from 'react';
import { useAuth } from '../../contexts/Auth';
import { EnRoles } from '../../interfaces/Roles';

const WithShopManager = (
    Component: React.FC,
    FallBackComponent?: React.FC,
): React.FC => {
    const { data } = useAuth();
    const roles = data?.role?.map((item) => item.type) ?? [];
    if (data && roles?.includes(EnRoles.shopManager)) {
        // eslint-disable-next-line react/display-name
        return () => <Component />;
    }
    if (FallBackComponent) {
        // eslint-disable-next-line react/display-name
        return () => <FallBackComponent />;
    }
    // eslint-disable-next-line react/display-name
    return () => <></>;
};

export default WithShopManager;
