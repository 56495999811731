import { Button, Space, Typography, theme } from 'antd';
import styled, { createGlobalStyle } from 'styled-components';

const { Title } = Typography;

export default createGlobalStyle`
  .ant-modal-footer {
    border: none;
    padding: 0px 50px 25px 50px;
  }
`;

export const ContentModal = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;

    .ant-checkbox-inner {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 29px;
        height: 29px;
    }

    .ant-checkbox-inner::after {
        transform: scale(2) rotate(45deg);
        position: relative;
        top: -6%;
        inset-inline-start: 0;
    }
`;
export const TextData = styled.text``;
export const TextTitle = styled(Title)``;
export const ContentTitle = styled.div``;
export const ContentFooter = styled.div``;

export const BackgroundTransparent = styled.div`
    position: absolute;
    height: 60px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
    left: 0;
    right: 0;
    bottom: 0;
`;

export const AreaContent = styled.div`
    overflow: hidden;
    position: relative;
    margin-top: 12px;
    flex: 1;
    height: 100%;

    &::-webkit-scrollbar {
      display: none;
    }

    &::-ms-overflow-style: none;
    &::scrollbar-width: none;
`;

export const CardContent = styled.div`
    padding: 0px 0px 100px 0px;

    &::-webkit-scrollbar {
        display: none;
    }

    &::-ms-overflow-style: none;
    &::scrollbar-width: none;
`;

export const ButtonDelete = styled(Button).attrs({
    children: 'Excluir',
})`
    padding: 0 100px;
    position: absolute;
    left: 0px;
    top: 0px;

    @media only screen and (max-width: 850px) {
        position: relative;
    }
`;

export const SpaceButton = styled(Space)`
    display: flex;
    position: absolute;
    right: 0px;
    top: 0px;

    @media only screen and (max-width: 850px) {
        position: relative;
    }
`;

export const TitleResolved = styled.h3`
    font-size: 16px;
    font-weight: 500;
    color: #787878;
    margin: 5px 15px 0px 0px;
`;
