import React, { createContext, useState, useContext } from 'react';
import { ThemeProvider } from 'styled-components';
import lightTheme from '../../assets/theme/light';
import darkTheme from '../../assets/theme/dark';

export const themeContext = createContext<IThemeContext>({} as IThemeContext);

type Props = {
    children: React.ReactNode;
};

interface IThemeContext {
    toggleTheme: () => void;
    theme: string;
}

const ThemeContext: React.FC<Props> = ({ children }) => {
    const [theme, setTheme] = useState('light');

    const toggleTheme = () => {
        setTheme((prev) => (prev === 'light' ? 'dark' : 'light'));
    };

    return (
        <themeContext.Provider value={{ theme, toggleTheme }}>
            <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
                {children}
            </ThemeProvider>
        </themeContext.Provider>
    );
};

function useTheme(): IThemeContext {
    const context = useContext(themeContext);
    const { toggleTheme, theme } = context;
    return {
        toggleTheme,
        theme,
    };
}

export { useTheme };

export default ThemeContext;
