import { useMutation } from '@tanstack/react-query';
import api from '../../../services/api';
import queryClient from '../../queryClient';
import { QUERY_KEY_ADMIN } from '../useGetAnnouncementsAdmin';

interface DeleteAnnouncementParam {
    id: string;
}

export const deleteAnnouncement = async (param: DeleteAnnouncementParam) => {
    try {
        const { data } = await api.delete(`/internal-notification/${param.id}`);
        return data;
    } catch (error: any) {
        throw new Error(error.response.data.message);
    }
};

export function useDeleteAnnouncement() {
    return useMutation({
        mutationFn: (param: DeleteAnnouncementParam) =>
            deleteAnnouncement(param),
        onSuccess: () => {
            queryClient.invalidateQueries([QUERY_KEY_ADMIN]);
        },
    });
}
