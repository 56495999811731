import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { IRouteProps } from '../../routes/routes';
// auth context
import { useAuth } from '../../contexts/Auth';

const LoginRoute: React.FC<IRouteProps> = ({ component, exact, path }) => {
    const { isAuth } = useAuth();

    const location: { state: { from: { pathname: string } } } = useLocation();
    const redirectableLink = location.state?.from?.pathname;

    return (
        <Route
            exact={exact}
            path={path}
            component={
                isAuth
                    ? () => <Redirect to={redirectableLink || '/'} />
                    : component
            }
        />
    );
};

export default LoginRoute;
