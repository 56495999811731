import styled, { css } from 'styled-components';
import { Avatar, Menu } from 'antd';

export const Container = styled.div`
    display: flex;
    align-items: center;
`;

export const Notification = styled.div`
    display: flex;
    align-items: center;
    margin-right: 29px;
    cursor: pointer;
    position: relative;
`;

export const NotificationIndicator = styled.div`
    position: absolute;
    right: 2px;
    top: 1px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #ff4e4e;
`;

export const StyledAvatar = styled(Avatar)`
    && {
        ${({ theme }) => css`
            /* background-color: ${theme.colors.accentColor}; */
            cursor: pointer;
        `}
    }
`;

export const StyledMenu = styled(Menu)`
    && {
        ${({ theme }) => css`
            color: ${theme.colors.font};
            min-width: 20rem;
            border-radius: ${theme.borderRadius.default};
            box-shadow: 0 3px 6px -4px rgb(0 0 0 / 10%),
                0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 4%);
        `}
    }
`;

export const StyledMenuItem = styled(Menu.Item)`
    && {
        ${({ theme }) => css`
            &:focus {
                border-right: 2px solid ${theme.colors.accentColor};
            }
        `}
    }
`;

export const StyledMenuItemVersion = styled(Menu.Item)`
    font-size: 1rem;
    align-self: flex-end;
    flex: 1;
    padding: 2px 8px;
    align-items: end;
`;

export const StyledMenuDivider = styled(Menu.Divider)`
    && {
        ${({ theme }) => css`
            margin: 0;
            background-color: ${theme.colors.lightGrey};
        `}
    }
`;

export const PresentationMenu = styled.div`
    display: flex;
    flex-direction: column;
    line-height: 0.8rem;
    padding: 1rem 2rem;
`;

export const Icon = styled.img`
    user-select: none;
`;
