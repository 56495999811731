import { useMutation } from '@tanstack/react-query';
import api from '../../../services/api';

export interface RequestResolved {
    internal_notification_id: string;
    resolved: boolean;
}

const StatusChangedResolvedFetch = async (reqBody: RequestResolved) => {
    const { data } = await api.post(
        `/shopmanager/internal-notification/resolve`,
        {
            ...reqBody,
        },
    );

    return data;
};

export const useChangeStatusResolved = () => {
    return useMutation({
        mutationFn: (reqBody: RequestResolved) =>
            StatusChangedResolvedFetch(reqBody),
    });
};
