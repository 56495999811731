// ENV ESPECIFICS

const development = {
    API_URL: 'https://api.staging.shoppbud.com.br/',
} as const;

const staging = {
    API_URL: 'https://api.staging.shoppbud.com.br/',
} as const;

const production = {
    API_URL: 'https://api.shoppbud.com.br/',
} as const;

const envs = {
    development,
    production,
    staging,
} as const;

const env = (
    import.meta.env.DEV
        ? 'development'
        : import.meta.env.NODE_ENV || 'production'
) as keyof typeof envs;

//
const SENTRY_DSN =
    import.meta.env.VITE_SENTRY_DSN ??
    'https://ec9897933e3849e49f502edc57b28918@o4505200927440896.ingest.sentry.io/4505347496673280';

const GOOGLE_MAPS_API_KEY = import.meta.env.VITE_GOOGLE_MAPS_API_KEY ?? '';
const API_URL = import.meta.env.VITE_API_URL ?? envs[env].API_URL;

const values = {
    ENV: env,
    GOOGLE_MAPS_API_KEY,
    SENTRY_DSN,
    API_URL,
};

export default values;
