/* eslint-disable no-param-reassign */
import { message } from 'antd';
import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../contexts/Auth';
import api from './api';

type Props = {
    children: React.ReactNode;
};

export const WithAxios: React.FC<Props> = ({ children }) => {
    const { logOut, resetAssumedToken, isAssuming, isAuth } = useAuth();
    const history = useHistory();

    const apiUpdate = useCallback(() => {
        // when will make a request, by default, put the avaiable token on local storage to request bearer
        api.interceptors.request.use(
            (config: { headers: { common: { Authorization: string } } }) => {
                config.headers.common.Authorization = `Bearer ${
                    localStorage.getItem('@NeerHouse:assumedToken') ??
                    localStorage.getItem('@NeerHouse:token')
                }`;

                return config;
            },
            (error: any) => {
                return Promise.reject(error);
            },
        );

        // when response status code is 401 (unathorized), the interceptor make an auto logout
        api.interceptors.response.use(
            (response: any) => {
                return response;
            },
            async (error: any) => {
                if (error.message === 'Request Cancelada') {
                    return Promise.reject(new Error('Request Cancelada'));
                }
                if (error.response.status === 401) {
                    if (error.response.data.error === 'read-only-permission') {
                        message.error(error.response.data.message);
                        return Promise.reject(error);
                    }

                    if (localStorage.getItem('@NeerHouse:assumedToken')) {
                        resetAssumedToken();
                    }
                    message.info(
                        error.response.data.message ||
                            'Sessão atual expirou, por favor, entre novamente',
                    );
                    logOut();
                    history.push('/login');

                    return Promise.reject(error);
                }
                return Promise.reject(error);
            },
        );
    }, [isAssuming, isAuth, history]);

    useEffect(() => {
        apiUpdate();
    }, [apiUpdate]);

    return <>{children}</>;
};
