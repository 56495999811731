import styled, { css } from 'styled-components';
import { HTMLProps } from 'react';
import Sider from 'antd/lib/layout/Sider';
import { Menu } from 'antd';

interface ITriggerProps extends HTMLProps<HTMLButtonElement> {
    isCollapsed: boolean;
}
// interface IWithisCollapsed {
//     isCollapsed: boolean;
// }

interface MenuProps {
    children: React.ReactNode;
    popupClassName: string;
    title: React.ReactNode;
}

export const Container = styled(Sider)`
    && {
        ${({ theme, collapsed }) => css`
            overflow-y: scroll;
            background-color: ${theme.colors.white};
            border-right: 1px solid ${theme.colors.lightGrey};
            overflow-y: auto;
            overflow-x: hidden;

            .ant-menu-title-content .ant-menu-submenu-arrow {
                display: ${collapsed ? 'none' : 'inline'};
            }
            .ant-menu-item-selected {
                color: ${theme.colors.accentColor};
            }
            .ant-menu-item::after {
                border: 1px solid ${theme.colors.accentColor};
            }

            ::-webkit-scrollbar {
                display: none;
            }
        `}
    }
`;

export const StyledMenu = styled(Menu)`
    && {
        ${({ theme }) => css`
            .ant-layout-sider-trigger {
                background: ${theme.colors.accentColor};
            }
            .ant-menu-submenu-selected {
                background: ${theme.colors.selectedColor};
                .ant-menu-title-content {
                    color: ${theme.colors.accentColor};
                }
            }
        `}
    }
`;

export const StyledMenuItem = styled(Menu.Item)`
    && {
        ${({ theme }) => css`
            text-align: left;
            padding: 0 3.4rem 0 2.8rem;

            .ant-menu-submenu-selected {
                color: ${theme.colors.accentColor};
                .ant-menu-title-content {
                    background-color: ${theme.colors.fail};
                }
            }
        `}
    }
`;

export const StyledSubMenu = styled(Menu.SubMenu)<MenuProps>`
    && {
        ${({ theme }) => css`
            .ant-menu-submenu-selected {
                color: ${theme.colors.accentColor};
            }
        `}
    }
`;

export const StyledDivider = styled(Menu.Divider)`
    && {
        ${({ theme }) => css`
            margin: 0;
            background-color: ${theme.colors.lightGrey};
        `}
    }
`;

export const StyledMenuItemGroup = styled(Menu.ItemGroup)`
    && {
        .ant-menu-item-group-title {
            padding: ${(props) => props.title === '' && 0};
        }
    }
`;

export const LogoContainer = styled.div`
    ${({ theme }) => css`
        height: 6rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${theme.colors.white};
        font-size: 1.6rem;

        .logo {
            width: 70%;
        }

        .icon {
            width: 70%;
        }

        img {
            margin-top: 1.5rem;
            width: 100%;
        }
    `}
`;

export const SiderTrigger = styled.button<ITriggerProps>`
    ${({ theme, isCollapsed }) => css`
        background: ${theme.colors.accentColor};
        color: #fff;
        cursor: pointer;
        transition: all 0.2s;
        width: ${isCollapsed ? '8rem' : '25rem'};
        border: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        font-size: 1.8rem;
        svg {
            transition: 0.3s ease;
        }
    `}
`;

export const Version = styled.span`
    display: flex;
    justify-content: center;
    font-size: 1.1rem;
    color: lightgrey;
    font-weight: bold;
`;

export const Icon = styled.img`
    width: 14px;
    height: 14px;
    margin-right: 10px;
    margin-bottom: 4px;
`;
