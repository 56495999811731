// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getRemoteConfig, fetchAndActivate } from 'firebase/remote-config';
import { getAnalytics, initializeAnalytics } from 'firebase/analytics';

const firebaseConfig = {
    apiKey: 'AIzaSyB8Z4EJo5GmKmxde9hvd8GRb8kChIVj1Ec',
    authDomain: 'shoppbud-admin.firebaseapp.com',
    projectId: 'shoppbud-admin',
    storageBucket: 'shoppbud-admin.appspot.com',
    messagingSenderId: '43232916311',
    appId: '1:43232916311:web:9974b799db13dfcaf9c412',
    measurementId: 'G-L534DEH4PE',
};

export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const remoteConfig = getRemoteConfig(app);

remoteConfig.settings.minimumFetchIntervalMillis = 1 * 60 * 1000;

initializeAnalytics(app);
fetchAndActivate(remoteConfig).then();

analytics.app.automaticDataCollectionEnabled = true;
