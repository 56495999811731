import React, { createContext, useContext } from 'react';

// types
interface IMenuContext {
    isCollapsed: boolean;
    setCollapsed: (value: boolean) => void;
    toogleCollapsed: () => void;
}

// context
export const menuContext = createContext<IMenuContext>({} as IMenuContext);

const MenuContext: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [isCollapsed, setIsCollapsed] = React.useState(true);

    const setCollapsed = (value: boolean) => {
        setIsCollapsed(value);
    };

    const toogleCollapsed = () => {
        setIsCollapsed((prev) => !prev);
    };
    return (
        <menuContext.Provider
            value={{
                isCollapsed,
                setCollapsed,
                toogleCollapsed,
            }}
        >
            {children}
        </menuContext.Provider>
    );
};

function useMenu(): IMenuContext {
    const context = useContext(menuContext);
    const { isCollapsed, setCollapsed, toogleCollapsed } = context;
    return {
        isCollapsed,
        setCollapsed,
        toogleCollapsed,
    };
}

export { useMenu };
export default MenuContext;
