export enum EnRoles {
    // master admin
    sysAdmin = 'sysAdmin',
    // can read and edit
    admin = 'admin',
    // only read
    roAdmin = 'roAdmin',
    // shop manager
    shopManager = 'shopManager',
    // customer
    customer = 'customer',
    // customer
    restocker = 'restocker',
    // customer
    trustee = 'trustee',
}

export function listPublicRoles(): EnRoles[] {
    return [
        EnRoles.admin,
        EnRoles.roAdmin,
        EnRoles.shopManager,
        EnRoles.sysAdmin,
    ];
}

export function listAdminRoles(): EnRoles[] {
    return [EnRoles.sysAdmin, EnRoles.roAdmin, EnRoles.admin];
}

export function listUsersRoles(): EnRoles[] {
    return [EnRoles.restocker, EnRoles.trustee];
}

export function getLabelRoles(role: EnRoles): string {
    switch (role) {
        case EnRoles.sysAdmin:
            return 'Usuário Super Admin';
        case EnRoles.admin:
            return 'Usuário Leitura e Adição';
        case EnRoles.roAdmin:
            return 'Usuário Só Leitura';
        case EnRoles.shopManager:
            return 'Lojista';
        case EnRoles.customer:
            return 'Cliente';
        case EnRoles.restocker:
            return 'Lojista';
        case EnRoles.trustee:
            return 'Síndico';
        default:
            return '';
    }
}
