import React from 'react';
// contexts
import { ConfigProvider, message } from 'antd';
import AuthContext from './Auth';
import ThemeContext from './Theme';
import MenuContext from './Menu';
import UpdateContext from './Update';
import TopTitleContext from './TopTitle';

type Props = {
    children: React.ReactNode;
};

const ContextBind: React.FC<Props> = ({ children }) => {
    message.config({
        maxCount: 2,
    });

    return (
        <ThemeContext>
            <TopTitleContext>
                <AuthContext>
                    <MenuContext>
                        <UpdateContext>
                            <ConfigProvider>{children}</ConfigProvider>
                        </UpdateContext>
                    </MenuContext>
                </AuthContext>
            </TopTitleContext>
        </ThemeContext>
    );
};

export default ContextBind;
