import React from 'react';
import { Data } from '../../contexts/Auth';
import { EnRoles } from '../../interfaces/Roles';

const WithRole =
    (data: Data, roles?: EnRoles[]) =>
    (Component: React.FC, FallBackComponent?: React.FC): React.FC => {
        // const { data } = useAuth();
        const profile_role = data?.role?.map((item) => item.type) ?? [];
        if (
            roles === undefined ||
            profile_role.some((item) => roles.includes(item))
        ) {
            // eslint-disable-next-line react/display-name
            return () => <Component />;
        }
        if (FallBackComponent) {
            // eslint-disable-next-line react/display-name
            return () => <FallBackComponent />;
        }
        // eslint-disable-next-line react/display-name
        return () => <></>;
    };

export default WithRole;
