import React, { createContext, useState } from 'react';

interface TopTitleProps {
    title: string;
    titleValue: (title: string) => void;
    graphLoading: boolean;
    setGraphLoading: (loading: boolean) => void;
}

export const topTitleContext = createContext<TopTitleProps>(
    {} as TopTitleProps,
);

const TopTitleContext: React.FC<{ children: React.ReactNode }> = ({
    children,
}) => {
    const [title, setTitle] = useState<string>('');
    const [graphLoading, setLoading] = useState<boolean>(true);

    const titleValue = (title: string) => {
        setTitle(title);
    };

    const setGraphLoading = (loading: boolean) => {
        setLoading(loading);
    };

    return (
        <topTitleContext.Provider
            value={{ title, titleValue, graphLoading, setGraphLoading }}
        >
            {children}
        </topTitleContext.Provider>
    );
};

export default TopTitleContext;
