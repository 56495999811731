import { useQuery } from '@tanstack/react-query';
import { IGetAnnouncementsPagination } from './types';
import api from '../../services/api';
import { useAuth } from '../../contexts/Auth';
import { EnRoles } from '../../interfaces/Roles';

export const QUERY_KEY_SHOPMANAGER = 'ANNOUNCEMENTS_NOT_VIEW';

export function useGetAnnouncementsShopmanager(params?: Object) {
    const { data: authData, isAssuming } = useAuth();
    const isAdmin = authData?.role.some(
        (role: { type: string }) =>
            role.type === EnRoles.shopManager || isAssuming,
    );

    const useGetAnnouncementsShopmanager = async () => {
        try {
            const { data }: { data: IGetAnnouncementsPagination } =
                await api.get('/shopmanager/internal-notification', {
                    params: {
                        ...params,
                    } as Record<string, any>, // Defina a tipagem do objeto params
                });
            return data;
        } catch (error: any) {
            throw new Error(error.response.data.message);
        }
    };

    return useQuery(
        [QUERY_KEY_SHOPMANAGER, params],
        useGetAnnouncementsShopmanager,
        {
            refetchInterval: 1 * 1000 * 60 * 2,
            enabled: isAdmin ?? false,
        },
    );
}
