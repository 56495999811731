import React from 'react';
import { ModalProps } from 'antd';
import { withTheme, DefaultTheme, ThemeProps } from 'styled-components';
import { WarningOutlined } from '@ant-design/icons';
import { StyledModal } from './styles';

interface IProps extends ThemeProps<DefaultTheme>, ModalProps {
    message?: string;
}

const ConfirmModal: React.FC<IProps> = ({
    children,
    theme,
    message,
    ...rest
}) => {
    return (
        <StyledModal okText="Confirmar" cancelText="Cancelar" {...rest}>
            <div
                style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <WarningOutlined
                    style={{
                        color: `${theme.colors.fail}`,
                        fontSize: '3.2rem',
                        marginBottom: 25,
                    }}
                />
                <div style={{ background: 'transparent', flex: 1 }}>
                    {children ?? (
                        <p style={{ margin: 0, textAlign: 'center' }}>
                            {message ||
                                'Você tem certeza que deseja concluir essa ação?'}
                        </p>
                    )}
                </div>
            </div>
        </StyledModal>
    );
};

export default withTheme(ConfirmModal);
