import axios, { AxiosRequestConfig } from 'axios';
import env from '../env';

const api = axios.create({
    baseURL: env.API_URL,
});

api.interceptors.request.use(async (config: AxiosRequestConfig) => {
    const { headers } = config;
    headers['x-platform'] = 'admin';
    return config;
});

api.prototype.Cancel = () => {
    axios.CancelToken.source();
};

export default api;
