import { DefaultTheme } from 'styled-components';
import staticTheme from './default';

const darkTheme: DefaultTheme = {
    colors: {
        backgroundColor: 'black',
        black: '#000',
        green: '#12776e',
        translucentGreen: '#12776ebf',
        white: '#fff',
        accentColor: '#4b7cf3',
        secondaryColor: '#78a3ff',
        selectedColor: '#78a3ff',

        lightGrey: '#e4e9f0',

        fail: '#f5222e',
        sucess: '#41b883',

        darkFont: '#141322',
        lightFont: '#5f6294',
        font: '#595c97',
    },
    ...staticTheme,
};

export default darkTheme;
