import React from 'react';
import { RouteProps } from 'react-router-dom';
// type
import { EnRoles } from '../interfaces/Roles';
// route helpers
import PrivateRoute from '../helpers/routes/PrivateRoute';
import PublicRoute from '../helpers/routes/PublicRoute';
import LogInRoute from '../helpers/routes/LogInRoute';

// interface props to custom router components
export interface IRouteProps extends RouteProps {
    role?: EnRoles[];
}
// Interface routing
interface IRoute extends RouteProps {
    route: React.FC<IRouteProps>;
    role?: EnRoles[];
    path: string;
}

const adminRole = [EnRoles.admin, EnRoles.roAdmin, EnRoles.sysAdmin];
const allAdminsWithoutRoAdminRole = [EnRoles.admin, EnRoles.sysAdmin];

const shopManagerRole = [EnRoles.shopManager];

const myProfileRoles = [
    EnRoles.admin,
    EnRoles.roAdmin,
    EnRoles.sysAdmin,
    EnRoles.shopManager,
];

// routes
const DashboardComponentV3 = React.lazy(() => import('../pages/Dashboard'));

const root: IRoute = {
    path: '/',
    component: DashboardComponentV3,
    exact: true,
    route: PrivateRoute,
};

const sign_in: IRoute = {
    path: '/login',
    component: React.lazy(() => import('../pages/Authentication/LogIn')),
    exact: true,
    route: LogInRoute,
};

// forgot password
const forgot_password: IRoute = {
    path: '/forgotpassword',
    component: React.lazy(
        () => import('../pages/Authentication/ForgotPassword'),
    ),
    exact: true,
    route: PublicRoute,
};

// reset password
const account_validation: IRoute = {
    path: '/account-validation',
    component: React.lazy(
        () => import('../pages/Authentication/AccountValidation'),
    ),
    exact: true,
    route: PublicRoute,
    role: adminRole,
};

// reset password
const reset_password: IRoute = {
    path: '/reset-password',
    component: React.lazy(
        () => import('../pages/Authentication/ResetPassword'),
    ),
    exact: true,
    route: PublicRoute,
};

// signup
const sign_up: IRoute = {
    path: '/signUp',
    component: React.lazy(() => import('../pages/Authentication/SignUp')),
    exact: true,
    route: PublicRoute,
};

// create shopManager
const list_shop_manager: IRoute = {
    path: '/users/shopmanager/list',
    component: React.lazy(
        () => import('../pages/Users/ShopManager/ListShopManager'),
    ),
    exact: true,
    route: PrivateRoute,
    role: adminRole,
};

// list admin
const list_admin: IRoute = {
    path: '/users/admin/list',
    component: React.lazy(() => import('../pages/Users/Admin/ListAdmin')),
    exact: true,
    route: PrivateRoute,
    role: adminRole,
};

// create store
const create_store: IRoute = {
    path: '/stores/create',
    component: React.lazy(() => import('../pages/Store/CreateStore')),
    exact: true,
    route: PrivateRoute,
    role: shopManagerRole,
};

// create provider
const create_provider: IRoute = {
    path: '/providers/create',
    component: React.lazy(
        () => import('../pages/Users/Supplier/CreateProvider'),
    ),
    exact: true,
    route: PrivateRoute,
    role: shopManagerRole,
};

// create advert
const create_advert: IRoute = {
    path: '/adverts/create',
    component: React.lazy(() => import('../pages/Advert/CreateAdvert')),
    exact: true,
    route: PrivateRoute,
    role: shopManagerRole,
};

// create provider
const list_provider: IRoute = {
    path: '/providers/list',
    component: React.lazy(() => import('../pages/Users/Supplier/ListProvider')),
    exact: true,
    route: PrivateRoute,
    role: shopManagerRole,
};

// list provider
const list_advert: IRoute = {
    path: '/adverts/list',
    component: React.lazy(() => import('../pages/Advert/ListAdvert')),
    exact: true,
    route: PrivateRoute,
    role: shopManagerRole,
};

// update provider
const update_provider: IRoute = {
    path: '/providers/update/:id',
    component: React.lazy(
        () => import('../pages/Users/Supplier/UpdateProvider'),
    ),
    exact: true,
    route: PrivateRoute,
};

// update advert
const update_advert: IRoute = {
    path: '/adverts/update/:id',
    component: React.lazy(() => import('../pages/Advert/UpdateAdvert')),
    exact: true,
    route: PrivateRoute,
};

// advert admin

const create_advert_admin: IRoute = {
    path: '/adverts/admin/create',
    component: React.lazy(() => import('../pages/AdvertAdmin/CreateAdvert')),
    exact: true,
    route: PrivateRoute,
    role: allAdminsWithoutRoAdminRole,
};

const list_advert_admin: IRoute = {
    path: '/adverts/admin/list',
    component: React.lazy(() => import('../pages/AdvertAdmin/ListAdvert')),
    exact: true,
    route: PrivateRoute,
    role: allAdminsWithoutRoAdminRole,
};

const update_advert_admin: IRoute = {
    path: '/adverts/admin/update/:id',
    component: React.lazy(() => import('../pages/AdvertAdmin/UpdateAdvert')),
    exact: true,
    route: PrivateRoute,
    role: allAdminsWithoutRoAdminRole,
};

// create admin
const create_admin: IRoute = {
    path: '/users/admin/create',
    component: React.lazy(() => import('../pages/Users/Admin/CreateAdmin')),
    exact: true,
    route: PrivateRoute,
    role: allAdminsWithoutRoAdminRole,
};

// update admin
const update_admin: IRoute = {
    path: '/users/admin/update/:id',
    component: React.lazy(() => import('../pages/Users/Admin/UpdateAdmin')),
    exact: true,
    route: PrivateRoute,
    role: allAdminsWithoutRoAdminRole,
};

// create shopManager
const create_shop_manager: IRoute = {
    path: '/users/shopmanager/create',
    component: React.lazy(
        () => import('../pages/Users/ShopManager/CreateShopManager'),
    ),
    exact: true,
    route: PrivateRoute,
    role: adminRole,
};

// update shopManager
const update_shopmanager: IRoute = {
    path: '/users/shopmanager/update/:id',
    component: React.lazy(
        () => import('../pages/Users/ShopManager/UpdateShopManager'),
    ),
    exact: true,
    route: PrivateRoute,
    role: allAdminsWithoutRoAdminRole,
};

// create shopManager V2
const create_shop_manager_V2: IRoute = {
    path: '/users/shopmanagerV2/create',
    component: React.lazy(() => import('../pages/Users/ShopManagerV2/index')),
    exact: true,
    route: PrivateRoute,
    role: adminRole,
};

// update shopManager V2
const update_shopmanager_V2: IRoute = {
    path: '/users/shopmanagerV2/update/:id',
    component: React.lazy(
        () => import('../pages/Users/ShopManagerV2/UpdateShopManager'),
    ),
    exact: true,
    route: PrivateRoute,
    role: allAdminsWithoutRoAdminRole,
};

// update client
const update_client: IRoute = {
    path: '/users/client/update/:id',
    component: React.lazy(() => import('../pages/Users/Client/UpdateClient')),
    exact: true,
    route: PrivateRoute,
    role: allAdminsWithoutRoAdminRole,
};

// list store
const list_store: IRoute = {
    path: '/stores/list',
    component: React.lazy(() => import('../pages/Store/ListStore')),
    exact: true,
    route: PrivateRoute,
    role: shopManagerRole,
};

const details_store_data: IRoute = {
    path: '/stores/details/data/:id',
    component: React.lazy(() => import('../pages/Store/DetailsStore/Tabs')),
    exact: true,
    route: PrivateRoute,
    role: shopManagerRole,
};

const details_store_planogram: IRoute = {
    path: '/stores/details/planogram/:id',
    component: React.lazy(() => import('../pages/Store/DetailsStore/Tabs')),
    exact: true,
    route: PrivateRoute,
    role: shopManagerRole,
};

const details_store_transfers: IRoute = {
    path: '/stores/details/transfers/:id',
    component: React.lazy(() => import('../pages/Store/DetailsStore/Tabs')),
    exact: true,
    route: PrivateRoute,
    role: shopManagerRole,
};

const details_sotre_transfers_create: IRoute = {
    path: '/stores/details/:id/transfer',
    component: React.lazy(
        () =>
            import('../pages/Store/DetailsStore/Tabs/Transfers/CreateTransfer'),
    ),
    exact: true,
    route: PrivateRoute,
    role: shopManagerRole,
};

const details_sotre_transfers_update: IRoute = {
    path: '/stores/details/:storeId/transfer/:transferId',
    component: React.lazy(
        () =>
            import('../pages/Store/DetailsStore/Tabs/Transfers/UpdateTransfer'),
    ),
    exact: true,
    route: PrivateRoute,
    role: shopManagerRole,
};

const details_store_picklist_create: IRoute = {
    path: '/stores/details/:storeId/picklist-create',
    component: React.lazy(
        () =>
            import('../pages/Store/DetailsStore/Tabs/Transfers/CreatePicklist'),
    ),
    exact: true,
    route: PrivateRoute,
    role: shopManagerRole,
};

const details_store_picklist_update: IRoute = {
    path: '/stores/details/:storeId/picklist/:transferId',
    component: React.lazy(() => import('../components/UpdatePicklist')),
    exact: true,
    route: PrivateRoute,
    role: shopManagerRole,
};

const details_store_purchases: IRoute = {
    path: '/stores/details/purchases/:id',
    component: React.lazy(() => import('../pages/Store/DetailsStore/Tabs')),
    exact: true,
    route: PrivateRoute,
    role: shopManagerRole,
};

const details_store_create_purchase: IRoute = {
    path: '/stores/details/purchases/:id/create',
    component: React.lazy(
        () =>
            import('../pages/Store/DetailsStore/Tabs/Purchase/CreatePurchase'),
    ),
    exact: true,
    route: PrivateRoute,
    role: shopManagerRole,
};

const details_store_update_purchase: IRoute = {
    path: '/stores/details/purchases/:id/update/:purchaseId',
    component: React.lazy(
        () => import('../components/purchase/UpdatePurchase'),
    ),
    exact: true,
    route: PrivateRoute,
    role: shopManagerRole,
};

const list_purchaes: IRoute = {
    path: '/purchase/list',
    component: React.lazy(() => import('../pages/Purchase/ListPurchases')),
    exact: true,
    route: PrivateRoute,
    role: shopManagerRole,
};

const update_purchase: IRoute = {
    path: '/purchase/:purchaseId',
    component: React.lazy(
        () => import('../components/purchase/UpdatePurchase'),
    ),
    exact: true,
    route: PrivateRoute,
    role: shopManagerRole,
};

const list_transfers: IRoute = {
    path: '/transfer/list',
    component: React.lazy(() => import('../pages/Transfer/ListTransfer')),
    exact: true,
    route: PrivateRoute,
    role: shopManagerRole,
};

const create_transfer: IRoute = {
    path: '/transfer/create',
    component: React.lazy(() => import('../pages/Transfer/CreateTransfer')),
    exact: true,
    route: PrivateRoute,
    role: shopManagerRole,
};

const create_picklist: IRoute = {
    path: '/picklist/create',
    component: React.lazy(() => import('../pages/Transfer/CreatePickList')),
    exact: true,
    route: PrivateRoute,
    role: shopManagerRole,
};

const update_transfer: IRoute = {
    path: '/transfer/:transferId',
    component: React.lazy(() => import('../pages/Transfer/UpdateTransfer')),
    exact: true,
    route: PrivateRoute,
    role: shopManagerRole,
};

const update_picklist: IRoute = {
    path: '/transfer/picklist/:transferId',
    component: React.lazy(() => import('../components/UpdatePicklist')),
    exact: true,
    route: PrivateRoute,
    role: shopManagerRole,
};

const details_store_stock: IRoute = {
    path: '/stores/details/stock/:id',
    component: React.lazy(() => import('../pages/Store/DetailsStore/Tabs')),
    exact: true,
    route: PrivateRoute,
    role: shopManagerRole,
};

const details_store_sales: IRoute = {
    path: '/stores/details/sales/:id',
    component: React.lazy(() => import('../pages/Store/DetailsStore/Tabs')),
    exact: true,
    route: PrivateRoute,
    role: shopManagerRole,
};
const list_open_lock: IRoute = {
    path: '/stores/details/locklist/:id',
    component: React.lazy(() => import('../pages/Store/DetailsStore/Tabs')),
    exact: true,
    route: PrivateRoute,
    role: shopManagerRole,
};

const list_indicators: IRoute = {
    path: '/stores/details/indicators/:id',
    component: React.lazy(() => import('../pages/Store/DetailsStore/Tabs')),
    exact: true,
    route: PrivateRoute,
    role: shopManagerRole,
};

// show user data
const show_user_data: IRoute = {
    path: '/users/data',
    component: React.lazy(() => import('../pages/MyProfile/ShowAndUpdateUser')),
    exact: true,
    route: PrivateRoute,
    role: myProfileRoles,
};

// create category
const store_category: IRoute = {
    path: '/categories/create',
    component: React.lazy(() => import('../pages/Category/CreateCategory')),
    exact: true,
    route: PrivateRoute,
    role: shopManagerRole,
};

// list category
const list_category: IRoute = {
    path: '/categories/list',
    component: React.lazy(() => import('../pages/Category/ListCategory')),
    exact: true,
    route: PrivateRoute,
    role: shopManagerRole,
};

// update category
const update_category: IRoute = {
    path: '/categories/update/:id',
    component: React.lazy(() => import('../pages/Category/UpdateCategory')),
    exact: true,
    route: PrivateRoute,
    role: shopManagerRole,
};

// list product
const list_product: IRoute = {
    path: '/products/list',
    component: React.lazy(() => import('../pages/Product/ListProduct')),
    exact: true,
    route: PrivateRoute,
    role: shopManagerRole,
};

// create product
const store_product: IRoute = {
    path: '/products/create',
    component: React.lazy(() => import('../pages/Product/CreateProduct')),
    exact: true,
    route: PrivateRoute,
    role: shopManagerRole,
};

// update product
const update_product: IRoute = {
    path: '/products/update/:id',
    component: React.lazy(() => import('../pages/Product/UpdateProduct')),
    exact: true,
    route: PrivateRoute,
    role: shopManagerRole,
};

// create product global
const store_product_global: IRoute = {
    path: '/product_global/create',
    component: React.lazy(() => import('../pages/ProductGlobal/CreateProduct')),
    exact: true,
    route: PrivateRoute,
    role: allAdminsWithoutRoAdminRole,
};

// list product global
const list_product_global: IRoute = {
    path: '/product_global/list',
    component: React.lazy(() => import('../pages/ProductGlobal/ListProduct')),
    exact: true,
    route: PrivateRoute,
    role: allAdminsWithoutRoAdminRole,
};

// update product global
const update_product_global: IRoute = {
    path: '/product_global/update/:id',
    component: React.lazy(() => import('../pages/ProductGlobal/UpdateProduct')),
    exact: true,
    route: PrivateRoute,
    role: allAdminsWithoutRoAdminRole,
};

// create category global
const store_category_global: IRoute = {
    path: '/categories_global/create',
    component: React.lazy(
        () => import('../pages/CategoriesGlobal/CreateCategory'),
    ),
    exact: true,
    route: PrivateRoute,
    role: allAdminsWithoutRoAdminRole,
};

// list category
const list_category_global: IRoute = {
    path: '/categories_global/list',
    component: React.lazy(
        () => import('../pages/CategoriesGlobal/ListCategory'),
    ),
    exact: true,
    route: PrivateRoute,
    role: allAdminsWithoutRoAdminRole,
};

// update category
const update_category_global: IRoute = {
    path: '/categories_global/update/:id',
    component: React.lazy(
        () => import('../pages/CategoriesGlobal/UpdateCategory'),
    ),
    exact: true,
    route: PrivateRoute,
    role: allAdminsWithoutRoAdminRole,
};

// notification push v2
const list_notification_v2: IRoute = {
    path: '/notifications/v2/list',
    component: React.lazy(
        () => import('../pages/PushNotificationV2/NotificationList'),
    ),
    exact: true,
    route: PrivateRoute,
    role: myProfileRoles,
};

const create_notification_v2: IRoute = {
    path: '/notifications/v2/create',
    component: React.lazy(
        () => import('../pages/PushNotificationV2/CreateNotification'),
    ),
    exact: true,
    route: PrivateRoute,
    role: myProfileRoles,
};

const update_notification_v2: IRoute = {
    path: '/notifications/v2/edit/:id',
    component: React.lazy(
        () => import('../pages/PushNotificationV2/UpdateNotification'),
    ),
    exact: true,
    route: PrivateRoute,
    role: myProfileRoles,
};

// list notification
const list_notification: IRoute = {
    path: '/notifications/list',
    component: React.lazy(
        () => import('../pages/PushNotification/NotificationList'),
    ),
    exact: true,
    route: PrivateRoute,
    role: myProfileRoles,
};

// create notification
const create_notification: IRoute = {
    path: '/notifications/create',
    component: React.lazy(
        () => import('../pages/PushNotification/CreateNotification'),
    ),
    exact: true,
    route: PrivateRoute,
    role: myProfileRoles,
};

// Edit notification
const update_notification: IRoute = {
    path: '/notifications/edit/:id',
    component: React.lazy(
        () => import('../pages/PushNotification/UpdateNotification'),
    ),
    exact: true,
    route: PrivateRoute,
    role: myProfileRoles,
};

// list notification logs
const list_notification_logs: IRoute = {
    path: '/notification/logs',
    component: React.lazy(
        () => import('../pages/PushNotificationV2/NotificationLogs'),
    ),
    exact: true,
    route: PrivateRoute,
    role: myProfileRoles,
};

const list_announcement: IRoute = {
    path: '/announcements',
    component: React.lazy(() => import('../pages/Announcements')),
    exact: true,
    route: PrivateRoute,
    role: allAdminsWithoutRoAdminRole,
};

const list_announcement_view: IRoute = {
    path: '/announcements/view/:id?',
    component: React.lazy(
        () => import('../pages/Announcements/ViewShopManager'),
    ),
    exact: true,
    route: PrivateRoute,
    role: shopManagerRole,
};

const create_announcement: IRoute = {
    path: '/announcements/create',
    component: React.lazy(
        () => import('../pages/Announcements/CreateOrUpdate'),
    ),
    exact: true,
    route: PrivateRoute,
    role: allAdminsWithoutRoAdminRole,
};

const update_announcement: IRoute = {
    path: '/announcements/edit/:id',
    component: React.lazy(
        () => import('../pages/Announcements/CreateOrUpdate'),
    ),
    exact: true,
    route: PrivateRoute,
    role: allAdminsWithoutRoAdminRole,
};

// create client
const create_client: IRoute = {
    path: '/users/client/create',
    component: React.lazy(() => import('../pages/Users/Client/CreateClient')),
    exact: true,
    route: PrivateRoute,
    role: allAdminsWithoutRoAdminRole,
};

// list client
const list_client: IRoute = {
    path: '/users/client/list',
    component: React.lazy(() => import('../pages/Users/Client/ListClient')),
    exact: true,
    route: PrivateRoute,
    role: adminRole,
};

// list stock
const list_stock: IRoute = {
    path: '/stocks/list',
    component: React.lazy(() => import('../pages/Stock/ListStock')),
    exact: true,
    route: PrivateRoute,
    role: myProfileRoles,
};

const list_adjustments: IRoute = {
    path: '/stocks/adjustments',
    component: React.lazy(() => import('../pages/Stock/ListAdjustments/index')),
    exact: true,
    route: PrivateRoute,
    role: shopManagerRole,
};

// list sale
const list_sale: IRoute = {
    path: '/sales/list',
    component: React.lazy(() => import('../pages/Sale/ListSale')),
    exact: true,
    route: PrivateRoute,
    role: myProfileRoles,
};

// list coupon
const list_coupon: IRoute = {
    path: '/coupons/list',
    component: React.lazy(() => import('../pages/Coupon/ListCoupon')),
    exact: true,
    route: PrivateRoute,
    role: myProfileRoles,
};

// discount_coupon
const create_coupon: IRoute = {
    path: '/coupons/create',
    component: React.lazy(() => import('../pages/Coupon/CreateCoupon')),
    exact: true,
    route: PrivateRoute,
    role: myProfileRoles,
};

// discount_coupon
const update_coupon: IRoute = {
    path: '/coupons/update/:id',
    component: React.lazy(() => import('../pages/Coupon/UpdateCoupon')),
    exact: true,
    route: PrivateRoute,
    role: myProfileRoles,
};

// task_regime
const tax_regime: IRoute = {
    path: '/sales/list/taxregime/:id',
    component: React.lazy(() => import('../components/TaxFiscal')),
    exact: true,
    route: PrivateRoute,
    role: myProfileRoles,
};

// Settings
const settings: IRoute = {
    path: '/settings',
    component: React.lazy(() => import('../pages/Settings')),
    exact: true,
    route: PrivateRoute,
    role: allAdminsWithoutRoAdminRole,
};

const financial: IRoute = {
    path: '/financial',
    component: React.lazy(() => import('../pages/Financial')),
    exact: true,
    route: PrivateRoute,
    role: myProfileRoles,
};

const create_financial: IRoute = {
    path: '/financial/create',
    component: React.lazy(
        () => import('../pages/Financial/CreateFinancialRegister'),
    ),
    exact: true,
    route: PrivateRoute,
    role: myProfileRoles,
};

const update_financial: IRoute = {
    path: '/financial/update/:id',
    component: React.lazy(
        () => import('../pages/Financial/UpdateFinancialRegister'),
    ),
    exact: true,
    route: PrivateRoute,
    role: myProfileRoles,
};

// create product global
const store_providers_global: IRoute = {
    path: '/providers_global/create',
    component: React.lazy(
        () => import('../pages/ProvidersGlobal/CreateProvider'),
    ),
    exact: true,
    route: PrivateRoute,
    role: allAdminsWithoutRoAdminRole,
};

// list product global
const list_providers_global: IRoute = {
    path: '/providers_global/list',
    component: React.lazy(
        () => import('../pages/ProvidersGlobal/ListProvider'),
    ),
    exact: true,
    route: PrivateRoute,
    role: allAdminsWithoutRoAdminRole,
};

// update product global
const update_providers_global: IRoute = {
    path: '/providers_global/update/:id',
    component: React.lazy(
        () => import('../pages/ProvidersGlobal/UpdateProvider'),
    ),
    exact: true,
    route: PrivateRoute,
    role: allAdminsWithoutRoAdminRole,
};

const transfer_validation: IRoute = {
    path: '/accept',
    component: React.lazy(() => import('../pages/Transfer/TransferValidation')),
    exact: true,
    route: PublicRoute,
    role: myProfileRoles,
};

const wallet: IRoute = {
    path: '/wallet',
    component: React.lazy(() => import('../pages/Wallet/tabs')),
    exact: true,
    route: PrivateRoute,
    role: myProfileRoles,
};

const walletBalance: IRoute = {
    path: '/wallet/balance',
    component: React.lazy(() => import('../pages/Wallet/tabs')),
    exact: true,
    route: PrivateRoute,
    role: myProfileRoles,
};

const walletReport: IRoute = {
    path: '/wallet/report',
    component: React.lazy(() => import('../pages/Wallet/tabs')),
    exact: true,
    route: PrivateRoute,
    role: myProfileRoles,
};

const list_users: IRoute = {
    path: '/shopmanagers/users/list',
    component: React.lazy(() => import('../pages/Trustee/ListTrustee')),
    exact: true,
    route: PrivateRoute,
    role: myProfileRoles,
};

const create_users: IRoute = {
    path: '/shopmanagers/users/create',
    component: React.lazy(() => import('../pages/Trustee/CreateTrustee')),
    exact: true,
    route: PrivateRoute,
    role: myProfileRoles,
};

const update_users: IRoute = {
    path: '/shopmanagers/users/update/:id',
    component: React.lazy(() => import('../pages/Trustee/UpdateTrustee')),
    exact: true,
    route: PrivateRoute,
    role: myProfileRoles,
};

const planogram_automation: IRoute = {
    path: '/planogram/automation',
    component: React.lazy(() => import('../pages/PlanogramAutomation')),
    exact: true,
    route: PrivateRoute,
    role: shopManagerRole,
};

const managing_stores: IRoute = {
    path: '/stores',
    component: React.lazy(() => import('../pages/StoreManagement')),
    exact: true,
    route: PrivateRoute,
    role: allAdminsWithoutRoAdminRole,
};

const tickets: IRoute = {
    path: '/invoice',
    component: React.lazy(() => import('../pages/Invoice')),
    exact: true,
    route: PrivateRoute,
    role: myProfileRoles,
};

const imports: IRoute = {
    path: '/imports/reports',
    component: React.lazy(() => import('../pages/ImportsReports')),
    exact: true,
    route: PrivateRoute,
    role: myProfileRoles,
};

const charges: IRoute = {
    path: '/charges',
    component: React.lazy(() => import('../pages/Charges')),
    exact: true,
    route: PrivateRoute,
    role: allAdminsWithoutRoAdminRole,
};

const charges_create: IRoute = {
    path: '/charges/create',
    component: React.lazy(() => import('../pages/Charges/CreateOrUpdate')),
    exact: true,
    route: PrivateRoute,
    role: allAdminsWithoutRoAdminRole,
};

const charges__update: IRoute = {
    path: '/charges/update/:id',
    component: React.lazy(() => import('../pages/Charges/CreateOrUpdate')),
    exact: true,
    route: PrivateRoute,
    role: allAdminsWithoutRoAdminRole,
};

const routes = [
    root,
    sign_in,
    account_validation,
    forgot_password,
    reset_password,
    sign_up,
    create_shop_manager,
    list_shop_manager,
    create_store,
    create_provider,
    list_provider,
    list_advert,
    update_advert,
    update_provider,
    create_advert,
    create_admin,
    list_admin,
    update_admin,
    update_shopmanager,
    list_store,
    details_store_data,
    details_store_planogram,
    details_store_transfers,
    details_sotre_transfers_create,
    details_store_picklist_create,
    details_sotre_transfers_update,
    details_store_picklist_update,
    details_store_stock,
    details_store_sales,
    show_user_data,
    store_category,
    list_category,
    update_category,
    list_product,
    store_product,
    update_product,
    create_client,
    list_client,
    update_client,
    list_transfers,
    create_transfer,
    update_transfer,
    create_picklist,
    update_picklist,
    details_store_purchases,
    details_store_create_purchase,
    details_store_update_purchase,
    list_stock,
    list_purchaes,
    update_purchase,
    list_sale,
    create_coupon,
    list_coupon,
    update_coupon,
    tax_regime,
    list_notification,
    create_notification,
    update_notification,
    list_product_global,
    store_product_global,
    update_product_global,
    update_category_global,
    store_category_global,
    list_category_global,
    list_notification_logs,
    list_announcement,
    list_announcement_view,
    create_announcement,
    update_announcement,
    settings,
    financial,
    create_financial,
    update_financial,
    store_providers_global,
    list_providers_global,
    update_providers_global,
    transfer_validation,
    wallet,
    walletReport,
    walletBalance,
    list_users,
    create_users,
    update_users,
    list_adjustments,
    list_open_lock,
    list_indicators,
    list_notification_v2,
    create_notification_v2,
    update_notification_v2,
    create_advert_admin,
    update_advert_admin,
    list_advert_admin,
    planogram_automation,
    managing_stores,
    create_shop_manager_V2,
    update_shopmanager_V2,
    tickets,
    imports,
    charges,
    charges_create,
    charges__update,
];

export default routes;
