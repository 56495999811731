import React from 'react';
import { ReloadOutlined } from '@ant-design/icons';
import { ThemeProps, DefaultTheme, withTheme } from 'styled-components';
import { Button, message } from 'antd';
import { useHistory } from 'react-router-dom';
import { Container, HelperContainer } from './styles';
import { useAuth } from '../../../contexts/Auth';

const AssumingBox: React.FC<ThemeProps<DefaultTheme>> = () => {
    const { resetAssumedToken, isAssuming } = useAuth();

    const history = useHistory();

    const onClick = () => {
        resetAssumedToken();
        history.push('/');
        message.info('Você acaba de voltar a sua visão');
        window.location.reload();
    };

    return (
        <HelperContainer>
            <Container isAssuming={isAssuming}>
                <Button
                    className="default-btn"
                    onClick={onClick}
                    icon={<ReloadOutlined />}
                >
                    Voltar para visão de Admin
                </Button>
            </Container>
        </HelperContainer>
    );
};

export default withTheme(AssumingBox);
