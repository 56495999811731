import styled, { css } from 'styled-components';
import { Header } from 'antd/lib/layout/layout';
import { TitleProps } from 'antd/lib/typography/Title';

export const Container = styled(Header)`
    && {
        ${({ theme }) => css`
            background-color: ${theme.colors.white};
            width: 100%;
            display: flex;
            border-bottom: 1px solid ${theme.colors.lightGrey};
            justify-content: space-between;
            padding: 0 2rem;
        `}
    }
`;

export const Title = styled.h2<TitleProps>`
    && {
        ${({ theme }) => css`
            margin-top: '1.25rem';
            font-weight: '0.2';
            color: ${theme.colors.accentColor};
            font-size: ${theme.fontSizes.big};
            white-space: nowrap;
        `}
    }
`;
