import { getAll } from 'firebase/remote-config';
import { remoteConfig } from '../services/firebase';

export const getFormattedRemoteConfig = () => {
    const parameters = getAll(remoteConfig);
    const formattedConfig: { [key: string]: string } = {};

    for (const key in parameters) {
        if (Object.prototype.hasOwnProperty.call(parameters, key)) {
            const entry = parameters[key];
            formattedConfig[key] = entry.asString();
        }
    }

    return formattedConfig;
};
