import { useQuery } from '@tanstack/react-query';
import { IGetAnnouncementsPagination } from './types';
import api from '../../services/api';

export const QUERY_KEY_ADMIN = 'ANNOUNCEMENTS_ADMIN';

export function useGetAnnouncementsAdmin(params: Object) {
    const getAnnouncementsAdmin = async () => {
        try {
            const { data }: { data: IGetAnnouncementsPagination } =
                await api.get('/internal-notification', {
                    params: {
                        ...params,
                    } as Record<string, any>, // Defina a tipagem do objeto params
                });
            return data;
        } catch (error: any) {
            throw new Error(error);
        }
    };

    return useQuery([QUERY_KEY_ADMIN, params], getAnnouncementsAdmin, {
        refetchInterval: 1 * 1000 * 60 * 5,
    });
}
