import React, { useState, useEffect } from 'react';
import { AnnouncementData } from '../store/Announcement/types';
import { useAuth } from '../contexts/Auth';
import { PopUpAnnouncement } from '../components/Modals/PopUpAnnouncement/popUpAnnouncement';
import { useGetAnnouncementsShopmanager } from '../store/Announcement';
import { EnRoles } from '../interfaces/Roles';

interface AnnouncementProviderProps {
    children: React.ReactNode;
}

const AnnouncementProvider: React.FC<AnnouncementProviderProps> = ({
    children,
}) => {
    const [dataModal, setDataModal] = useState<AnnouncementData>(
        {} as AnnouncementData,
    );
    const [open, setOpen] = useState(false);

    const { data: authData, isAssuming } = useAuth();

    const isShopManager = authData?.role.some(
        (role: { type: string }) => role.type === EnRoles.shopManager,
    );

    const { data: announcemments } = useGetAnnouncementsShopmanager({
        'filters[isRead]': false,
        'filters[showPopup]': true,
    });

    const getAnnouncement = (announcement: AnnouncementData[]) => {
        const filter = announcement.find(
            (el) => el.show_popup && !el.recipients[0].read_at,
        );

        if (filter && isShopManager && !isAssuming) {
            setDataModal(filter);
            setOpen(true);
        }
    };

    useEffect(() => {
        if (announcemments?.data.length) {
            getAnnouncement(announcemments.data);
        }
    }, [announcemments?.data, isShopManager]);

    return (
        <>
            {isShopManager && open && !isAssuming && (
                <PopUpAnnouncement dataModal={dataModal} openModal={setOpen} />
            )}
            {children}
        </>
    );
};

export default AnnouncementProvider;
