/* eslint-disable react/no-danger */
import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import {
    DislikeOutlined,
    LikeOutlined,
    PaperClipOutlined,
} from '@ant-design/icons';
import {
    Button,
    Checkbox,
    CheckboxProps,
    Modal,
    Space,
    Typography,
    message,
} from 'antd';
import { useHistory } from 'react-router-dom';
import { AnnouncementData } from '../../../store/Announcement/types';
import { useAuth } from '../../../contexts/Auth';
import RemoveBorderFooter, {
    ContentFooter,
    ContentModal,
    ContentTitle,
    TextData,
    TextTitle,
    BackgroundTransparent,
    AreaContent,
    CardContent,
    ButtonDelete,
    SpaceButton,
    TitleResolved,
} from './styles';
import { useUpdateAnnouncement } from '../../../store/Announcement/ShowAnnouncement/shopmanager';
import { useDeleteAnnouncement } from '../../../store/Announcement/DeleteAnnouncement';
import { EnRoles } from '../../../interfaces/Roles';
import { ConfirmModal } from '../../Layout';
import {
    RequestReaction,
    useCreateReactAnnouncementMutation,
} from '../../../store/Announcement/Reaction';
import { useChangeStatusResolved } from '../../../store/Announcement/Resolved';

interface Props {
    dataModal: AnnouncementData;
    openModal: React.Dispatch<React.SetStateAction<boolean>>;
}

type Reaction = 'LIKE' | 'DISLIKE' | null;

export const PopUpAnnouncement: React.FC<Props> = ({
    dataModal,
    openModal,
}) => {
    const { mutateAsync } = useUpdateAnnouncement();
    const { mutateAsync: mutateAsyncReact } =
        useCreateReactAnnouncementMutation();
    const { mutateAsync: mutateAsyncChangeStatusResolved } =
        useChangeStatusResolved();
    const [openDelete, setOpenDelete] = useState<boolean>(false);
    const { data: authData, isAssuming } = useAuth();

    const navigate = useHistory();

    const [resolved, setResolved] = useState(
        !dataModal?.recipients ? false : !!dataModal.recipients[0]?.resolved_at,
    );
    const {
        title,
        content,
        created_at: date,
        link,
        attachments,
        id,
        isPreview,
        recipients,
    } = dataModal;

    const [reaction, setReaction] = useState<RequestReaction>({
        internal_notification_id: String(id ?? ''),
        reaction: recipients?.length ? recipients[0].reaction : 'DISLIKE',
    });

    const handleReaction = async (newReaction: Reaction) => {
        try {
            await mutateAsyncReact({
                ...reaction,
                reaction: newReaction,
            });
            setReaction({
                ...reaction,
                reaction: newReaction,
            });
        } catch (error) {
            // erro
        }
    };

    const isShopManager = authData?.role.some(
        (role: { type: string }) => role.type === EnRoles.shopManager,
    );

    const isAdmin = authData?.role.some(
        (role: { type: string }) => role.type === EnRoles.sysAdmin,
    );

    const {
        mutateAsync: deleteMutate,
        isLoading: deleteLoading,
        isError,
    } = useDeleteAnnouncement();

    const data = dayjs(date).format('DD/MM/YY - HH:mm');
    const shortcut = link ? ` Link: ${link}` : '';

    const { Link } = Typography;

    const onConfirmModal = React.useCallback(() => {
        if (isShopManager && !isAssuming) {
            mutateAsync({ id: String(dataModal.id) });
        }
        openModal(false);
    }, [dataModal]);

    const deleteAnnouncement = async () => {
        if (!isAdmin) {
            message.error(
                'Desculpe, você não possui permissão para excluir este comunicado.',
            );
            return;
        }

        await deleteMutate({ id: String(id) });
        openModal(false);
        message.success('Comunicado deletado com sucesso!');
    };

    const onChangeAnnouncementResolved: CheckboxProps['onChange'] = async (
        e,
    ) => {
        try {
            await mutateAsyncChangeStatusResolved({
                internal_notification_id: String(id || ''),
                resolved: e.target.checked,
            });
            setResolved(e.target.checked);
        } catch {
            // nenhum erro
        }
    };

    useEffect(() => {
        if (isAdmin && !isPreview && isError) {
            message.error(
                'Ocorreu um erro ao tentar excluir o comunicado. Por favor, tente novamente mais tarde.',
            );
        }
    }, [isError, isAdmin, isPreview]);

    const { innerHeight } = window;

    return (
        <>
            <RemoveBorderFooter />
            <Modal
                centered
                open
                closable={false}
                onOk={onConfirmModal}
                onCancel={onConfirmModal}
                bodyStyle={{ height: innerHeight - 150, padding: '25px 50px' }}
                style={{ minWidth: '92vw' }}
                cancelText
                okText
                footer={[
                    <Space
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            position: 'relative',
                            flexWrap: 'wrap',
                        }}
                    >
                        {isAdmin && !isPreview && (
                            <ButtonDelete
                                danger
                                type="default"
                                onClick={() => setOpenDelete(true)}
                                disabled={deleteLoading}
                                style={{ maxWidth: 228 }}
                            />
                        )}
                        <Button
                            type="primary"
                            onClick={onConfirmModal}
                            block
                            style={{ padding: '0 100px', maxWidth: 228 }}
                        >
                            Fechar
                        </Button>
                        {isAdmin && !dataModal.isPreview && (
                            <SpaceButton>
                                <Button
                                    type="primary"
                                    onClick={() =>
                                        navigate.push(
                                            `/announcements/edit/${dataModal.id}`,
                                        )
                                    }
                                    block
                                    style={{
                                        padding: '0 100px',
                                        maxWidth: 228,
                                    }}
                                >
                                    Editar
                                </Button>
                            </SpaceButton>
                        )}
                        {isShopManager && !isAssuming && (
                            <SpaceButton>
                                <Button
                                    type="text"
                                    size="large"
                                    shape="circle"
                                    onClick={() =>
                                        handleReaction(
                                            reaction.reaction !== 'LIKE'
                                                ? 'LIKE'
                                                : null,
                                        )
                                    }
                                    style={{
                                        backgroundColor: 'transparent',
                                        color:
                                            reaction.reaction === 'LIKE'
                                                ? '#20AC54'
                                                : 'gray',
                                        marginRight: 15,
                                    }}
                                    icon={
                                        <LikeOutlined
                                            style={{
                                                color:
                                                    reaction.reaction === 'LIKE'
                                                        ? '#20AC54'
                                                        : 'gray',
                                            }}
                                        />
                                    }
                                >
                                    Gostei
                                </Button>
                                <Button
                                    type="text"
                                    size="large"
                                    shape="circle"
                                    onClick={() =>
                                        handleReaction(
                                            reaction.reaction !== 'DISLIKE'
                                                ? 'DISLIKE'
                                                : null,
                                        )
                                    }
                                    style={{
                                        backgroundColor: 'transparent',
                                        color:
                                            reaction.reaction === 'DISLIKE'
                                                ? '#FF0000'
                                                : 'gray',
                                        marginLeft: 15,
                                    }}
                                    icon={
                                        <DislikeOutlined
                                            style={{
                                                color:
                                                    reaction.reaction ===
                                                    'DISLIKE'
                                                        ? '#FF0000'
                                                        : 'gray',
                                            }}
                                        />
                                    }
                                >
                                    Não gostei
                                </Button>
                            </SpaceButton>
                        )}
                    </Space>,
                ]}
            >
                <ContentModal>
                    <ContentTitle>
                        <TextData style={{ fontSize: 16 }}>{data}</TextData>
                        <TextTitle
                            title={title}
                            ellipsis={{ rows: 2 }}
                            style={{ fontSize: 24, margin: 0 }}
                        >
                            {title}
                        </TextTitle>
                    </ContentTitle>
                    <AreaContent>
                        <CardContent
                            id="content"
                            style={{
                                textAlign: 'justify',
                                position: 'relative',
                                overflowY: 'auto',
                                height: '100%',
                            }}
                        >
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: content ?? '',
                                }}
                            />
                        </CardContent>
                        <BackgroundTransparent />
                    </AreaContent>
                    <ContentFooter>
                        <Space direction="vertical" style={{ width: '100%' }}>
                            <Link
                                href={shortcut.replace('Link:', '').trim()}
                                target="_blank"
                                style={{
                                    textDecorationLine: 'underline',
                                    fontSize: 16,
                                }}
                            >
                                {shortcut}
                            </Link>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent:
                                        attachments && attachments?.length > 0
                                            ? 'space-between'
                                            : 'flex-end',
                                }}
                            >
                                <Space
                                    style={{
                                        display: 'flex',
                                        maxWidth: isShopManager
                                            ? '70vw'
                                            : '85vw',
                                        overflowX: 'auto',
                                        flexDirection: 'row',
                                        whiteSpace: 'nowrap',
                                        marginTop: 4,
                                    }}
                                >
                                    {attachments?.map((el, indice) => {
                                        return (
                                            <Button
                                                type="link"
                                                icon={<PaperClipOutlined />}
                                                style={{
                                                    padding: 0,
                                                    color: 'gray',
                                                }}
                                                href={el.url}
                                                key={indice}
                                            >
                                                {el.name}
                                            </Button>
                                        );
                                    })}
                                </Space>
                                {isShopManager && !isAssuming && (
                                    <div
                                        style={{
                                            display: 'flex',
                                        }}
                                    >
                                        <TitleResolved>
                                            Marcar como resolvido?
                                        </TitleResolved>
                                        <Checkbox
                                            value={resolved}
                                            defaultChecked={resolved}
                                            style={{ width: 29, height: 29 }}
                                            onChange={
                                                onChangeAnnouncementResolved
                                            }
                                        />
                                    </div>
                                )}
                            </div>
                        </Space>
                    </ContentFooter>
                </ContentModal>
            </Modal>

            <ConfirmModal
                title="Excluir comunicado"
                visible={openDelete}
                onOk={deleteAnnouncement}
                onCancel={() => setOpenDelete(false)}
                okButtonProps={{ loading: deleteLoading }}
            />
        </>
    );
};
