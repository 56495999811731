import React, { ReactNode } from 'react';
import { getAll } from 'firebase/remote-config';
import { remoteConfig } from '../../services/firebase';

interface FlagsProviderProps {
    children: ReactNode;
}

const FlagsContext = React.createContext<YourFlagsType | null>(null);

interface YourFlagsType {
    PUSH_NOTIFICATION_V2: string;
}

const FlagsProvider: React.FC<FlagsProviderProps> = ({ children }) => {
    const [flags, setFlags] = React.useState<YourFlagsType>({
        PUSH_NOTIFICATION_V2: 'false',
    });

    const getFormattedRemoteConfig = () => {
        const parameters = getAll(remoteConfig);
        const formattedConfig: { [key: string]: string } = {};

        for (const key in parameters) {
            if (Object.prototype.hasOwnProperty.call(parameters, key)) {
                const entry = parameters[key];
                formattedConfig[key] = entry.asString();
            }
        }

        setFlags({ ...formattedConfig } as any);
    };

    React.useEffect(() => {
        getFormattedRemoteConfig();
    }, []);

    return (
        <FlagsContext.Provider value={flags}>{children}</FlagsContext.Provider>
    );
};

export default FlagsProvider;
