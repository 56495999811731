import styled, { css } from 'styled-components';
import { Collapse as CollapseComponent, CollapseProps } from 'antd';

interface PanelProps extends CollapseProps {
  dynamicPadding?: number;
}

export const Panel = styled(CollapseComponent.Panel)<PanelProps>`
    && {
        .ant-collapse-header {
            ${({ theme, dynamicPadding}) => css`
                color: ${theme.colors.font};
                font-size: ${theme.fontSizes.little};
                margin-bottom: 0.5rem;
                /* padding: ${dynamicPadding}; */
            `}
        }
    }
`;
export const Collapse = styled(CollapseComponent)`
    border: 0;
    background-color: transparent;
    border: none !important;
    //remove padding title
    .ant-collapse-item .ant-collapse-header {
        padding: 0;
        /* border: 0; */
    }
    //remove padding form
    .ant-collapse-item .ant-collapse-content-box {
        padding: 0;
    }

    /* hide the top line */
    .ant-collapse-item .ant-collapse-content {
        border: 0;
        /* padding-top: 20px; */
    }
    /* hide the bottom line */
    .ant-collapse-item {
        border: 0;
    }
`;
