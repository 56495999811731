import styled from 'styled-components';

interface AvatarProps {
    roundShape?: boolean;
}
export const Avatar = styled.img<AvatarProps>`
    width: 100%;
    height: 100%;
    border-radius: ${(props) => (props.roundShape ? '50%' : '10px')};
    border-style: none;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
`;

export const Container = styled.div`
    display: flex;
    width: 200px;
    height: 200px;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
`;
