import React from 'react';
import { useAuth } from '../../contexts/Auth';
import { EnRoles, listAdminRoles } from '../../interfaces/Roles';

const WithRoleAdmin = (
    Component: React.FC,
    FallBackComponent?: React.FC,
): React.FC => {
    const { isAdmin, data } = useAuth();

    const actualRoles = React.useMemo(() => {
        if (data && data.role) return data.role.map((item) => item.type);
        return [];
    }, [data]);

    if (data && isAdmin && !actualRoles?.includes(EnRoles.shopManager)) {
        return () => <Component />;
    }
    if (FallBackComponent) {
        return () => <FallBackComponent />;
    }
    return () => <></>;
};

export default WithRoleAdmin;
