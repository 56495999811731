import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { ArrowRightOutlined, MenuOutlined } from '@ant-design/icons';
import { Drawer } from 'antd';
import { Container, LogoContainer, SiderTrigger } from './styles';
import Menu from './Menu';
import { useMenu } from '../../../contexts/Menu';

import Logo from '../../../assets/images/new_logo.png';
import Icon from '../../../assets/images/logo-inhouse-preto-pequeno.svg';

const SideBar: React.FC = () => {
    const { isCollapsed, setCollapsed, toogleCollapsed } = useMenu();

    useEffect(() => {
        if (isMobile) setCollapsed(false);
    }, []);

    return (
        <>
            {!isMobile && (
                <Container
                    collapsible
                    width="25rem"
                    collapsedWidth="8rem"
                    collapsed={!isCollapsed}
                    onCollapse={() => toogleCollapsed()}
                    trigger={
                        <>
                            <SiderTrigger isCollapsed={!isCollapsed}>
                                <ArrowRightOutlined
                                    rotate={!isCollapsed ? 0 : 180}
                                />
                            </SiderTrigger>
                        </>
                    }
                >
                    <LogoContainer>
                        {!isCollapsed ? (
                            <img src={Icon} alt="icon" className="icon" />
                        ) : (
                            <img src={Logo} alt="icon" className="logo" />
                        )}
                    </LogoContainer>
                    <Menu />
                </Container>
            )}
            {isMobile && (
                <>
                    <MenuOutlined
                        rotate={180}
                        onClick={() => setCollapsed(true)}
                        style={{
                            position: 'fixed',
                            top: '30%',
                            fontSize: '1.3rem',
                            marginLeft: '0.5rem',
                        }}
                    />
                    <Drawer
                        mask={false}
                        style={{ touchAction: 'none' }}
                        closable
                        visible={isCollapsed}
                        placement="left"
                        width="100%"
                        onClose={() => setCollapsed(false)}
                    >
                        <Menu />
                    </Drawer>
                </>
            )}
        </>
    );
};

export default SideBar;
