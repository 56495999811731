import React from 'react';
import { StyledSubMenu } from './styles';

interface IProps {
    isCollapsed: boolean;
    children: React.ReactNode;
    title: string;
    icon: React.ReactNode;
}

const SubMenu: React.FC<IProps> = ({
    children,
    title,
    icon,
    isCollapsed,
    ...rest
}) => {
    return (
        <StyledSubMenu
            title={
                isCollapsed ? (
                    <>{icon}</>
                ) : (
                    <>
                        {icon}
                        <span>{title}</span>
                    </>
                )
            }
            popupClassName="styled-pop-up"
            {...rest}
        >
            {children}
        </StyledSubMenu>
    );
};

export default SubMenu;
