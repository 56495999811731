import React, { useContext, useEffect } from 'react';
import { topTitleContext } from '../../../contexts/TopTitle';

import AvatarMenu from '../AvatarMenu';
import { AssumingBox } from '..';

import { Container, Title } from './styles';

const TopBar: React.FC = () => {
    const { title, titleValue } = useContext(topTitleContext);

    useEffect(() => {
        if (!title) titleValue(title);
    }, [title]);

    return (
        <Container>
            <Title>{title}</Title>
            <AssumingBox />
            <AvatarMenu />
        </Container>
    );
};

export default TopBar;
