const staticTheme = {
    fontSizes: {
        default: '1.4rem',
        little: '1.2rem',
        mid: '1.6rem',
        big: '2.0rem',
        xbig: '2.5rem',
    },
    borderRadius: {
        default: '4px',
    },
    breakPoints: {
        xs: '0px',
        sm: '576px',
        md: '768px',
        lg: '992px',
        xl: '1200px',
        xxl: '1600px',
    },
};

export default staticTheme;
