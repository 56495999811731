import styled, { css } from 'styled-components';
import { HTMLProps } from 'react';

interface IContainerProps extends HTMLProps<HTMLDivElement> {
    isAssuming: boolean;
}

export const HelperContainer = styled.div`
    margin-left: auto;
    margin-right: 5rem;
`;

export const Container = styled.div<IContainerProps>`
    ${({ isAssuming }) => css`
        visibility: ${isAssuming ? 'visibility' : 'hidden'};

        button {
            animation: flashing 1.5s linear infinite;
        }
        button:hover {
            animation: none;
        }

        @keyframes flashing {
            50% {
                opacity: 0.3;
            }
        }
    `}
`;
