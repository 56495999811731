import React, { useCallback, useContext, useState, createContext } from 'react';

export interface IUpdateContext {
    updateData: () => void;
    isUpdated: boolean;
}

export const updateContext = createContext({} as IUpdateContext);

const UpdateContext: React.FC<{ children: React.ReactNode }> = ({
    children,
}) => {
    const [isUpdated, setIsupdated] = useState(false);

    const updateData = useCallback(() => {
        setIsupdated(!isUpdated);
    }, [isUpdated]);

    return (
        <updateContext.Provider value={{ updateData, isUpdated }}>
            {children}
        </updateContext.Provider>
    );
};

function useUpdate(): IUpdateContext {
    const context = useContext(updateContext);
    const { updateData, isUpdated } = context;
    return { updateData, isUpdated };
}

export default UpdateContext;

export { useUpdate };
