import styled, { css } from 'styled-components';
import { Content } from 'antd/lib/layout/layout';

export const StyledContent = styled(Content)`
    && {
        ${({ theme }) => css`
            background-color: ${theme.colors.white};
            max-width: 100vw;
        `}
    }
`;
