import { useMutation } from '@tanstack/react-query';
import { AnnouncementData } from '../types';
import queryClient from '../../queryClient';
import { QUERY_KEY_SHOPMANAGER } from '../useGetAnnouncementsShopmanager';
import api from '../../../services/api';

export interface ParamsUpdateAnnouncement {
    id: string;
}

export const useUpdateAnnouncement = () => {
    const mutationFn = async (params: ParamsUpdateAnnouncement) => {
        try {
            const { data }: { data: AnnouncementData } = await api.get(
                `/shopmanager/internal-notification/${params.id}`,
            );
            return data;
        } catch (error: unknown) {
            // error
        }
    };

    return useMutation(mutationFn, {
        onSuccess: () => {
            queryClient.invalidateQueries([QUERY_KEY_SHOPMANAGER]);
        },
    });
};
