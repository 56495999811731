import styled, { css } from 'styled-components';

export const Container = styled.main`
    ${({ theme }) => css`
        background-color: ${theme.colors.backgroundColor};

        display: flex;
        flex-direction: column;

        min-height: 100vh;
        min-width: 100vw;

        width: 100%;
        height: 100%;
    `}
`;
